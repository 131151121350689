.map {
  display: block;
  margin: 0 auto;
  height: auto;
  max-width: 1000px;
}

.station:hover {
  cursor: pointer;
}

.station:hover .dot {
  opacity: 1;
}

.station:hover .label {
  text-decoration: underline;
}
