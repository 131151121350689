body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 10px;
}

#root {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: hidden;
}

@media (min-width: 600px) {
  #root {
    height: 100%;
    max-height: none;
  }
}
